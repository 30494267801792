import $ from 'jquery'

class NavToggle {
    constructor() {
        this.elements = {
            doc: $(document),
            container: $('.c-offcanvas--menu'),
            mask: $('.c-offcanvas__mask'),
            // menuItemsWithChildren: $('.c-offcanvas--menu .menu__item--has-children > a'),
            // footerMenuItemsWithChildren: $('.c-nav-footer .menu__item--has-children > a'),
        }

        this.elements.container.on('click', this.hideOffcanvas)
        this.elements.mask.on('click', this.hideOffcanvas)
        // this.elements.menuItemsWithChildren.on("click", this.revealSubMenu)
        // this.elements.footerMenuItemsWithChildren.on("click", this.revealSubMenu)

        this.elements.doc.on( 'keyup', (e) => {
            if ( e.key === 'Escape' ) { // ESC
                $('body').removeClass('navigation--is-active c-page-book--is-active')
            }
        });
    }

    hideOffcanvas(event) {
        if (event.target === this) {
            $('body').removeClass('navigation--is-active c-page-book--is-active')
        }
    }

    // revealSubMenu(event) {
    //     event.preventDefault()
    //     let menuItem = $(event.target).parent()

    //     menuItem.toggleClass('open')
    //     menuItem.find('> .sub-menu').slideToggle()
    // }

    // revealOffcanvas(event) {
    //     event.preventDefault()
    //     $('.c-offcanvas--menu .menu__item').find('> .sub-menu').slideUp()
    //     $('.c-offcanvas--menu .menu__item').removeClass('open')

    //     setTimeout(function() {
    //         $('.c-nav-primary').toggleClass('c-nav-primary--nav-open')
    //     }, 500)
    // }
}

export default NavToggle
