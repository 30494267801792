import $ from 'jquery'

class CtaGridFilter {
    constructor() {

        $('.c-grid-filter__select').each(function(){
            var $this = $(this), numberOfOptions = $(this).children('option').length;

            $this.addClass('select-hidden');
            $this.wrap('<div class="select"></div>');
            $this.after('<div class="select-styled"></div>');

            var $styledSelect = $this.next('div.select-styled');
            $styledSelect.text($this.children('option').eq(0).text());

            var $list = $('<ul />', {
                'class': 'select-options js-filter'
            }).insertAfter($styledSelect);

            for (var i = 0; i < numberOfOptions; i++) {
                $('<li />', {
                    text: $this.children('option').eq(i).text(),
                    attr:{
                        text: $this.children('option').eq(i).text(),
                        ["data-filter-cta-grid"] : $this.children('option').eq(i).val()
                    }
                }).appendTo($list);
            }

            var $listItems = $list.children('li');

            $styledSelect.on("click", function(e) {
                e.stopPropagation();
                $('div.select-styled.active').not(this).each(function(){
                    $(this).removeClass('active').next('ul.select-options').hide();
                });
                $(this).toggleClass('active').next('ul.select-options').toggle();
            });

            $listItems.on("click", function(e) {
                e.stopPropagation();
                $styledSelect.text($(this).text()).removeClass('active');
                $this.val($(this).attr('data-filter-cta-grid'));

                $list.hide();
                $(this).parents('.js-filter').trigger('change');
            });

            $(document).on("click", function() {
                $styledSelect.removeClass('active');
                $list.hide();
            });

        });
    }
}

export default CtaGridFilter