import $ from 'jquery'

class fadeInNav {
    constructor(el, timing, delay) {
        $(el).each(function(i) {
            let setDelay = '' + (timing + (i * delay)) + 's, ' + (timing + (i * delay)) + 's'
            $(this).css('transition-delay', setDelay);
        });
    }
}

export default fadeInNav